


// ======================
// Component > Inputs
// =================================================
form button[type="submit"] {
	float: right; }

#form-feedback {
	background-color: $color-green;
	color: $color-black;
	opacity: 0;
	margin: 20px 0 0 0;
	padding: 0;
	text-align: center;
	transition: opacity 0.5s;

	p {
		margin: 0;
		padding: 20px; }

	a, a:visited {
		color: $color-black;
		text-decoration: none;

		&:hover {
			text-decoration: underline; } }

	&.bad {
		background-color: #f00; }

	&.show {
		opacity: 1; } }

.inputs {
	@include clearfix;
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		@include clearfix;
		margin: 0 0 $page-gutter 0;
		overflow: hidden;
		position: relative;

		span {
			background-color: #fff;
			border-radius: 3px;
			display: block;
			position: relative; }

		&.no-bg {
			background-color: transparent; } }

	@media only screen and (min-width: $bp-small + 100) {
		&.double {
			li {
				float: left;
				width: 50%;

				&:nth-of-type(2n + 1) {
					padding-right: $page-gutter * 0.5; }

				&:nth-of-type(2n + 2) {
					padding-left: $page-gutter * 0.5; } } } }

	button {
		float: right; }

	label {
		@include user-select(none);
		@include v-align;
		font: {
			size: 14px;
			weight: 700; }
		left: 20px;
		opacity: 0.5;
		position: absolute;
		transition: top 0.5s;
		z-index: 1;

		&.ta {
			@include transform(none);
			top: 24px; }

		&.active {
			top: 14px; } }

	input {
		background-color: transparent;
		border: none;
		height: 70px;
		line-height: 50px;
		padding: 10px 20px;
		position: relative;
		width: 100%;
		z-index: 2;

		@include placeholder {
			opacity: 0;
			transition: opacity 0.5s; }

		@include placeholder-focus {
			opacity: 0; } }

	textarea {
		background-color: transparent;
		border: none;
		border-radius: 3px;
		height: 200px;
		padding: 24px 20px;
		position: relative;
		width: 100%;
		z-index: 2;

		@include placeholder {
			opacity: 0;
			transition: opacity 1s; }

		@include placeholder-focus {
			opacity: 0; } } }

.text-area {
	padding: 0 0 $page-gutter 0;

	.text-area-wrap {
		background-color: #fff;
		border-radius: 3px; }

	label.ta {
		@include user-select(none);
		font: {
			size: 14px;
			weight: 700; }
		left: 20px;
		opacity: 0.5;
		position: absolute;
		top: 20px;
		transition: top 0.5s;
		z-index: 1;

		&.active {
			top: 2px; } }

	textarea {
		background-color: transparent;
		border: none;
		border-radius: 3px;
		height: 200px;
		padding: 24px 20px;
		position: relative;
		width: 100%;
		z-index: 2;

		@include placeholder {
			opacity: 0;
			transition: opacity 1s; }

		@include placeholder-focus {
			opacity: 0; } } }

.inputs, .text-area, .uploads {

	span.error {
		display: block;
		color: #f00;
		font: {
			size: 14px;
			weight: 700; }
		position: absolute;
		right: 10px;
		top: 5px;
		z-index: 1; }

	&.uploads span.error {
		position: relative;
		right: auto;
		top: auto; } }


@media only screen and (min-width: $bp-small + 100) {

	.contact-us-form {

		.inputs, .text-area {
			float: left;
			width: 50%; }

		.inputs {
			padding-right: ($page-gutter / 2); }

		.text-area {
			padding-left: ($page-gutter / 2);

			textarea {
				display: block;
				height: 250px; } } } }

