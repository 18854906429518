


// ======================
// Layout > Footer
// =================================================
footer {
	background-color: $color-black;
	height: 50vh;
	z-index: 200;

	.container {
		@include v-align; }

	p.linkedin {
		text-align: center;

		a {
			@include hide-text;
			background: {
				image: url(../images/linkedin-poly-w.png);
				position: center center;
				size: contain;
				repeat: no-repeat; }
			display: inline-block;
			height: 40px;
			opacity: 0.7;
			padding: {}
			transition: opacity 0.5s;
			width: 40px;

			&:hover {
				opacity: 1; } } }

	p.end {
		color: $color-white;
		margin: 0 auto;
		max-width: 400px;
		text-align: center;
		opacity: 0.5; }

	nav.nav-primary-footer {

		ul {
			list-style: none;
			margin: 0 0 1.5em 0;
			padding: 0;
			text-align: center; }

		li {
			display: inline-block;
			padding: 0 5px;

			a, a:visited {
				color: $color-white;
				text-decoration: none;

				&:hover {
					text-decoration: underline; } } } } }




