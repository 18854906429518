


// ======================
// Layout > Sections > Welcome
// =================================================
body.page-template-template-home-php section.welcome {
	background-color: transparent;
	height: 100vh;

	.section-wrap {
		@include v-align;
		color: $color-white;
		margin: 0 auto;
		max-width: 640px;
		text-align: center;

		//p span
 }		//olor: $color-brand

	.ai-mouse {
		opacity: 1;
		transition: opacity 1s;

		&.hide {
			opacity: 0; } } }
