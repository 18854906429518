


// ======================
// Utils > Mixins: vertical & horizontal alignment
// =================================================
@mixin v-align {
	@include transform(translateY(-50%));
	position: relative;
	top: 50%; }

@mixin h-align {
	@include transform(translateX(-50%));
	position: relative;
	left: 50%; }

