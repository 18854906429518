


// ======================
// Layout > Sections > Split information
// =================================================
body.page-template section .section-wrap.split-it {

	@media only screen and (min-width: $bp-medium) {
		h2 {
			text-align: center; }

		.split {
			@include clearfix; }

		.left, .right {
			float: left;
			width: 50%; }

		.left {
			border-right: 1px solid #ddd;
			padding-right: $page-gutter * 4; }

		.right {
			padding-left: $page-gutter * 4; } } }
