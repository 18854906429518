


// ======================
// Layout > Sections > Standard
// =================================================
body.page-template section {
	background-color: $color-background;

	.section-wrap {
		overflow: auto;
		margin: 0 auto;
		max-width: $bp-large;
		padding: $section-padding $page-gutter; }

	&.brand-color {
		background-color: $color-brand;

		.section-wrap {
			color: $color-white;
			padding: $page-gutter;

			@media only screen and (min-width: $bp-medium) {
				p {
					max-width: 80%; } } } } }
