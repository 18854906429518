


@import "../../node_modules/typi/scss/_typi";
@import "https://fonts.googleapis.com/css?family=Bitter:400,700|Lato:400,700";


// ======================
// Base > Typography setup
// =================================================
$breakpoints: (small: $bp-small, medium: $bp-medium, large: $bp-large);

$typi: (null: (16px, 1.5), small: 18px, large: 19px);

$h1-font-map: (null: (1.2em, 1.0), small: 1.4em);
$h2-font-map: (null: (1.6em, 1.0));
$h3-font-map: (null: (1.4em, 1.0));
$end-font-map: (null: (0.7em, 1.0));

$nav-font-map: (null: (1.2em, 2.5), small: 1.4em, medium: (0.9em, 2.0));
$welcome-font-map: (null: (1.2em, 1.5), large: 1.3em);

$stack-base: 'Lato', sans-serif;
$stack-brand: 'Bitter', serif;

html {
	@include typi-base();
	font-family: $stack-base;

	footer {
		@include typi($end-font-map); } }


//h1.brand
//ont-family: $stack-brand

//h1
//include typi($h1-font-map)

//h2
//include typi($h2-font-map)

h3, .h3 {
	@include typi($h3-font-map); }

h3, .h3, h4, .h4 {
	margin: 2.5em 0 0.3em 0; }

p {
	margin: 0 0 1.5em 0; }

header nav {
	@include typi($nav-font-map);
	letter-spacing: 0.05em;

	@media only screen and (min-width: $bp-medium) {
		letter-spacing: 0.1em; } }

section.welcome {
	@include typi($welcome-font-map);
	font: {
		family: $stack-brand;
		weight: 400; } }
