


// ======================
// Utils > Mixin: Easy placeholders for all kits.
// Including hovr and focus.
// =================================================
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content; }
	&:-moz-placeholder {
		@content; }
	&::-moz-placeholder {
		@content; }
	&:-ms-input-placeholder {
		@content; } }

@mixin placeholder-hover {
	&:hover::-webkit-input-placeholder {
		@content; }
	&:hover:-moz-placeholder {
		@content; }
	&:hover::-moz-placeholder {
		@content; }
	&:hover:-ms-input-placeholder {
		@content; } }

@mixin placeholder-focus {
	&:focus::-webkit-input-placeholder {
		@content; }
	&:focus:-moz-placeholder {
		@content; }
	&:focus::-moz-placeholder {
		@content; }
	&:focus:-ms-input-placeholder {
		@content; } }

