


// ======================
// Layout > Style the header elements
// =================================================
header.banner {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
	transition: color 0.5s;

	@media only screen and (min-width: $bp-medium) {
		.header-wrap {
			min-height: 100px; } }

	@media only screen and (max-width: $bp-medium - 1) {
		&.open {
			background-color: $color-background;

			.header-wrap {
				min-height: 80px; }

			a.brand {
				background-image: url(../images/topledere2.png); } } }

	.header-wrap {
		@include clearfix;
		margin: 0 auto;
		max-width: $bp-large;
		position: relative; }

	$icon-size: 40px;

	a.brand {
		//@include hide-text
		background: {
			image: url(../images/topledere2-w.png);
			repeat: no-repeat;
			size: contain; }
		display: block;
		height: $icon-size;
		left: $page-gutter;
		position: absolute;
		top: $page-gutter;
		transition: all 0.5s;
		width: $icon-size;
		z-index: 101;

		@media only screen and (min-width: $bp-medium) {
			height: $icon-size + 20;
			width: $icon-size + 20; }

		span {
			opacity: 0;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%; } }

	button {
		background-color: transparent;
		border: none;
		cursor: pointer;
		height: $icon-size;
		margin: 0;
		padding: 0;
		position: absolute;
		right: $page-gutter - 8px;
		top: $page-gutter;
		width: $icon-size;
		z-index: 101;

		@media only screen and (min-width: $bp-medium) {
			display: none; } }


	@media only screen and (max-width: $bp-medium - 1) {
		nav.nav-primary {
			background-color: $color-background;
			height: 0;
			opacity: 0;
			overflow: hidden;
			text-align: center;
			transition: opacity 0.5s;
			z-index: 100;

			ul {
				list-style: none;
				margin: 0;
				padding: 80px $page-gutter $page-gutter $page-gutter; }

			li {

				&.active {}

				a {
					color: $color-black;
					text-decoration: none;

					&:hover {
						color: $color-brand; } } } }

		&.open button .ai-hamburger span {
			background-color: $color-black; }

		&.open nav.nav-primary {
			height: auto;
			opacity: 1; } }

	@media only screen and (min-width: $bp-medium) {
		nav.nav-primary {
			@include v-align;
			position: absolute;
			right: 0;

			ul {
				@include clearfix;
				list-style: none;
				margin: 0;
				padding: 0; }

			li {
				float: left;
				margin-right: $page-gutter;

				&.menu-log-ind a {
					background-color: $color-brand;
					border-radius: 3px;

					&:hover {
						background-color: $color-white;
						color: $color-brand; } }

				a {
					color: $color-white;
					display: inline-block;
					padding: 0 $page-gutter;
					text-decoration: none;

					&:hover {
						color: $color-brand; } } } } } }

@media only screen and (min-width: $bp-medium) {
	body {
		&.page-template-template-apply-php, &.page-template-template-contact-php {
			header.banner nav.nav-primary {
				li {
					a {
						border-radius: 3px;

						&:hover {
							background-color: $color-white;
							color: $color-brand; } } } } } } }

