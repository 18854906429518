


// ======================
// Layout > Contact
// =================================================
section.contact-persons {

	.section-wrap {}

	.contact-person {
		margin: 0 auto;
		max-width: $bp-medium;
		padding: 50px 0;

		.contact-person-wrap {
			@include clearfix; }

		.picture {
			margin: 0 auto;
			max-width: 200px;

			img {} }

		.information {
			text-align: center;

			.name {
				color: $color-brand; }

			.position {
				font-style: italic; }

			.phone, .mail, .linkedin {
				margin: 0 auto 10px auto;
				padding: 0 0 0 50px;
				text-align: left; }

			div span {
				$size: 40px;
				background-color: #fff;
				border-radius: 50%;
				display: inline-block;
				height: $size;
				left: 0;
				position: absolute;
				top: 0;
				width: $size;

				img {
					@include v-align;
					display: block;
					margin: 0 auto;
					max-width: $size - 20; } }

			div a {
				display: inline-block;
				color: $color-black;
				line-height: 40px;
				text-decoration: none;

				&:hover {
					color: $color-brand;
					text-decoration: underline; } }

			.h3 {
				margin-top: 1em; } }

		@media only screen and (min-width: $bp-medium) {
			.picture {
				left: 0;
				position: absolute;
				top: 0; }

			.information {
				padding: 30px 0 0 250px;
				text-align: left; } } } }

