


// ======================
// Base > Theme colors
// =================================================
$color-brand: #0375b4;

$color-background: #efefef;
$color-background-header: #1a1b20;
$color-header: #eee;
$color-white: #eee;
$color-black: #212121;

$color-linkedin: #0077b5;

$color-green: #6bf178;
