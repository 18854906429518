


// ======================
// Component > Button
// =================================================
.bb-button {
	background-color: transparent;
	border: none;
	margin: 0;
	padding: 0;

	a, span {
		border: 2px solid $color-brand;
		border-radius: 3px;
		box-shadow: inset 0 0 0 0 $color-brand;
		color: $color-brand;
		cursor: pointer;
		display: inline-block;
		margin: 0 auto;
		height: 50px;
		line-height: 46px;
		padding: 0 20px;
		text-decoration: none;
		transition: all ease 0.3s;

		&:hover {
			box-shadow: inset 300px 0 0 0 $color-brand;
			color: $color-white; } } }

.bb-button-in {
	border: none;
	margin: 0;
	padding: 0;

	a, span {
		background-color: $color-linkedin;
		border-radius: 3px;
		color: $color-white;
		cursor: pointer;
		display: inline-block;
		margin: 0 auto;
		height: 40px;
		line-height: 38px;
		padding: 0 15px;
		text-decoration: none;
		transition: all ease 0.8s; } }

