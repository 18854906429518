


// ======================
// Component > Animated Mouse Scroll
// =================================================
.ai-mouse {
	@include animation(intro 1s);
	border: 2px solid #eee;
	border-radius: 15px;
	bottom: 40px;
	height: 56px;
	left: 50%;
	margin-left: -15px;
	position: absolute;
	width: 32px;

	.scroll {
		@include animation(finger 1s infinite);
		background: #eee;
		border-radius: 4px;
		display: block;
		height: 3px;
		margin: 6px auto;
		width: 3px; } }

body.page-template-template-home-php .ai-mouse {
	bottom: 5%;
	position: fixed; }

@keyframes intro {
	0% {
		@include transform(translateY(40px));
		opacity: 0; }
	100% {
		@include transform(translateY(0));
		opacity: 1; } }

@keyframes finger {
	0% {
		opacity: 1; }
	100% {
		@include transform(translateY(38px));
		opacity: 0; } }
