


// ======================
// Component > Animated Hamburger Icon
// =================================================
.ai-hamburger {
	@include transform(rotate(0deg));
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: block;
	height: 18px;
	margin: 0 auto;
	position: relative;
	transition: 0.5s ease-in-out;
	width: 24px;

	&.white span {
		background-color: #eee; }

	span {
		@include transform(rotate(0deg));
		@include transform-origin(left center);
		background-color: #000;
		display: block;
		height: 2px;
		left: 0;
		position: absolute;
		transition: 0.25s ease-in-out;
		width: 100%;

		&:nth-child(1) {
			top: 0; }

		&:nth-child(2) {
			top: 8px; }

		&:nth-child(3) {
			top: 16px; } }

	&.open span {

		&:nth-child(1) {
			@include transform(rotate(45deg));
			left: 3px; }

		&:nth-child(2) {
			opacity: 0;
			width: 100%; }

		&:nth-child(3) {
			@include transform(rotate(-45deg));
			left: 3px;
			top: 17px; } } }

