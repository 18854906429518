


// ======================
// Component > Uploads
// =================================================
.uploads .upload {
	font: {
		size: 14px;
		weight: 700; }
	margin-bottom: $page-gutter;

	@media only screen and (min-width: $bp-small + 100) {
		float: left;
		width: 50%;

		&:nth-of-type(2n + 1) {
			padding-right: ($page-gutter / 2); }

		&:nth-of-type(2n + 2) {
			padding-left: ($page-gutter / 2); }

		.upload-wrap {
			height: 120px; } }

	.upload-wrap {
		background-color: #fff;
		padding: ($page-gutter / 2) $page-gutter; }

	.title {
		opacity: 0.5; }

	.note {
		font: {
			style: italic;
			weight: 400; }
		opacity: 0.5; }

	.file {
		bottom: ($page-gutter / 2);
		left: $page-gutter;
		padding: ($page-gutter / 2) 0 0 0;
		position: absolute;

		input {} } }

