


// ======================
// Layout > Header image (banner)
// =================================================
.header-image {
	background: {
		position: right;
		repeat: no-repeat;
		size: cover; }
	height: 100%;
	left: 0;
	opacity: 0.7;
	padding: 0;
	position: fixed;
	top: 0;
	width: 100%; }
