


// ======================
// Base > Theme variables: Breakpoints
// =================================================
$bp-small: 600px;
$bp-medium: 920px;
$bp-large: 1100px;



// ======================
// Base > Theme variables: Misc
// =================================================
$page-gutter: 20px;
$section-padding: 120px;
