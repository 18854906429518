


// ======================
// Base > Setup standard things.
// =================================================
* {
	outline: 0 !important;
	box-sizing: border-box; }

html, body {
	height: 100%; }

section, div, body, aside, header, main, footer {
	position: relative; }

img {
	margin: 0 auto;
	max-width: 100%; }

.touch body {
	overflow: scroll;
	webkit-overflow-scrolling: touch; }

body {
	background-color: $color-background;

	&.page-template-template-home-php {
		background-color: $color-black;

		.page-header {
			display: none; } } }

.pre-loader {
	align-items: center;
	background-color: $color-background;
	display: flex;
	font-family: $stack-brand;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	transition: margin-top 1s;
	width: 100%;
	z-index: -1;

	span {
		max-width: 120px; } }

body.ready .pre-loader {
	margin-top: -50px; }

