


// ======================
// Layout > Section divider
// =================================================
div.section-divider {
	background-color: $color-background;

	.the-image-divider {
		background: {
			position: center;
			size: cover; }
		height: 50vh;
		opacity: 0.8; }

	.the-section-divider {
		height: 50vh; } }
