


// ======================
// Layout > Page header
// =================================================
.page-header {
	background-color: $color-brand;
	color: $color-white;
	overflow: auto;

	.page-header-wrap {
		margin: 0 auto;
		max-width: $bp-large;
		padding: 80px $page-gutter 0 $page-gutter; }

	h1 {
		margin: 2em 0 0.5em 0; } }

